var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"triggers-journal-group"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.stateChatConfigPath,
        'key': 'journal_users',
        'hasAnchor': true,
      }
    }}}),_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.stateChatConfigPath,
        'key': 'journal_system',
        'hasAnchor': true,
      }
    }}}),_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.stateChatConfigPath,
        'key': 'journal_settings',
        'hasAnchor': true,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }