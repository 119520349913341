










































import { InputSetups } from '@/mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components:{
    NestedContent,
    NotAvailableOptionsOverlay
  }
})
export default class TriggersJournalGroup extends Mixins<UseFields, InputSetups, TariffsTagsHelper>(UseFields, InputSetups, TariffsTagsHelper) {
}
