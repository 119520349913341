var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-journal-group"},[(!_vm.getTagsByFieldKey("binded_channel"))?[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'log_kick',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'log_leave',
          'hasAnchor': true,
        }
      }}}),(_vm.$store.state.chatState.chat.version >= 31000)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'log_user_prop_transition',
          'hasAnchor': true,
        }
      }}}):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'log_restrict',
          'hasAnchor': true,
        }
      }}})]:_c('not-available-options-overlay',{staticClass:"bg-transparent relative",attrs:{"tags":_vm.getTagsByFieldKey('binded_channel')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }