






























































































































































































































































































































































































import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    NestedContent
  }
})
export default class PenaltiesJournalGroup extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
}
