var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"triggers-journal-group"},[(!_vm.getTagsByFieldKey("binded_channel"))?[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'send_to_journal_trigger_call',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'send_to_journal_trigger_message_delete',
          'hasAnchor': true,
        }
      }}})]:_c('not-available-options-overlay',{staticClass:"bg-transparent relative",attrs:{"tags":_vm.getTagsByFieldKey('binded_channel')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }